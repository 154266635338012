import {useEffect, useState} from 'react';

export const GoogleMerchantReviewBadgeRating = () => {
    const [isClient, setIsClient] = useState(false);

    useEffect(() => {
        setIsClient(true);
    }, []);

    useEffect(() => {
        if (isClient) {
            let href = document?.location?.href;
            if (href) {
                let isLocalhost = href?.includes('localhost') || href?.includes('ngrok.io');
                if (isLocalhost) {
                    if (isLocalhost) {
                        return;
                    }
                }
            }

            const script = document.createElement('script');
            script.src = 'https://apis.google.com/js/platform.js?onload=renderBadge';
            script.async = true;
            script.defer = true;

            const renderBadge = () => {
                window.gapi.load('ratingbadge', () => {
                    window.gapi.ratingbadge.render('ratingbadge', {
                        merchant_id: 105155405
                    });
                });
            };
            script.onload = renderBadge;
            document.body.appendChild(script);
            return () => {
                document.body.removeChild(script);
            };
        }
    }, [isClient]);

    return <div id='ratingbadge' style={{position: 'fixed', bottom: '0', right: '0'}}></div>
};
